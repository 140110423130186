// Animate elements to appear in viewport
(function() {

    // One Page Navigation
    var nav = new SlideNav({
        activeClass: 'active',
        toggleButtonSelector: '.openNav',
        toggleBoxSelector: '.nav',
        changeHash: true,
        hideAfterSelect: false,
        speed: 100
    });

    // Add javascripts after initial page load
    document.onreadystatechange = () => {
        if (document.readyState === 'complete') {
            let script = document.createElement('script');
            script.src = "/js/lazyScripts.js";
            document.body.append(script);
        }
    };


    // Animations
    var AOS = require('AOS');
    AOS.init({
        disable: 'phone',
        duration: 700,
        once: true
    });

    /**
     * Mobile Navigation
     */
    function mobileNav() {
        var toggle = document.querySelector('#openNav');
        var close = document.querySelector('#closeNav');
        var sidenav = document.querySelector('#mobileNav');
        var body = document.querySelector('body');
        var navs = document.querySelectorAll('#mobileNav .nav-link');

        // Close nav on outer click
        body.addEventListener('click', ev => {
            if (!sidenav.contains(ev.target) && !toggle.contains(ev.target) && sidenav.classList.contains('open')) {
                sidenav.classList.remove('open');
            }
        });

        // Open / close nav
        toggle.addEventListener('click', ev => {
            ev.preventDefault();

            if (sidenav.classList.contains('open')) {
                sidenav.classList.remove('open');
            } else {
                sidenav.classList.add('open');
            }
        });

        // Close when navigation is clicked
        navs.forEach(el => {
            el.addEventListener('click', ev => {
                sidenav.classList.remove('open');
            })
        });

        // Close button
        close.addEventListener('click', ev => {
            sidenav.classList.remove('open');
        });

        // Add nav shadow
        window.addEventListener("scroll", function() {
            var navbar = document.querySelector('.navbar.fixed-top');

            if (window.scrollY > 100) {
                navbar.classList.add('shadow');
            } else {
                navbar.classList.remove('shadow');
            }
        });
    }

    mobileNav();
})();
