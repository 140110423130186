Request = {
    /**
     * perform a post request
     *
     * @param url
     * @return {Promise<unknown>}
     */
    post(url, data) {
        return new Promise(function (resolve, reject) {
            $.ajax({
                url: url,
                type: "POST",
                dataType: "json",
                data: data,
                beforeSend: function () {
                },
                success: function (data) {
                    resolve(data) // Resolve promise and when success
                },
                error: function (err) {
                    reject(err) // Reject the promise and go to catch()
                }
            });
        });
    },

    /**
     * perform a get request
     *
     * @param url
     * @return {Promise<unknown>}
     */
    get(url) {
        return new Promise(function (resolve, reject) {
            $.ajax({
                url: url,
                type: "GET",
                dataType: "json",
                beforeSend: function () {
                },
                success: function (data) {
                    resolve(data) // Resolve promise and when success
                },
                error: function (err) {
                    reject(err) // Reject the promise and go to catch()
                }
            });
        });
    }
}
